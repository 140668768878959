<template>
  <div>
    <b-container class="base-container-x pt-2" style="min-height:400px;background-color:#fff">
      <b-row class="mb-5 justify-content-center">
        <b-col cols="12">
          <StepFormRegister :currentStep="this.currentStep" :titleListSteps="this.titleListSteps" />
        </b-col>
        <b-col xl="9" lg="9" sm="12" md="12" class="border rounded py-4 py-md-5 border-light-gray">
          <h3 class="content-header-title text-primary-dark">{{ $t('Teacher information') }}</h3>
          <div class="mt-4">
            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
              <b-form-group>
                <validation-provider
                  :name="$t('WYA Teacher ID Number (If any)')"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <label for="teacher_id" class="text-primary-dark font-weight-600">{{ $t('WYA Teacher ID Number (If any)') }}</label>
                <b-form-input
                  id="teacher_id"
                  type="text"
                  v-model="teacherFormData.teacher_id"
                  :placeholder="$t('WYA Teacher ID Number (If any)')"
                ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <validation-provider
                  :name="$t('Name')"
                  rules="required"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <label for="school_name" class="text-primary-dark font-weight-600">{{ $t('Name') }} <span class="text-danger-light">*</span></label>
                <b-form-input
                  id="name"
                  type="text"
                  v-model="teacherFormData.name"
                  :placeholder="$t('Enter name')"
                ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <validation-provider
                    :name="$t('Date of birth')"
                    autocomplete="off"
                    rules="required"
                    v-slot="{errors}"
                  >
                <label for="birth-date" class="text-primary-dark font-weight-600">{{ $t('Date of birth') }} <span class="text-danger-light">*</span></label>
                <datepicker
                  :value="date"
                  v-model="teacherFormData.date_of_birth"
                  @input="dateBirthDay"
                  :dayStr="dayStr"
                  style="height:38px"
                  placeholder="YYYY-MM-DD"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <validation-provider
                    :name="$t('Nationality')"
                    autocomplete="off"
                    rules="required"
                    v-slot="{errors}"
                  >
                <label for="nationality" class="text-primary-dark font-weight-600">{{ $t('Nationality') }} <span class="text-danger-light">*</span></label>
                <b-form-select id="nationality" v-model="teacherFormData.nationality" value-field="text" text-field="text" :options="countries">
                  <template #first>
                    <b-form-select-option :value="null" disabled>{{ $t('Click Here') }}</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <validation-provider
                  :name="$t('Country of Residence')"
                  rules="required"
                  autocomplete="off"
                  v-slot="{errors}"
                >
              <b-form-group>
                <label for="country" class="text-primary-dark font-weight-600">{{ $t('Country of Residence') }} <span class="text-danger-light">*</span></label>
                <b-form-select id="country" v-model="teacherFormData.country_of_residence" value-field="value" text-field="text" :options="countries">
                  <template #first>
                    <b-form-select-option :value="null" disabled>{{ $t('Click Here') }}</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
              </validation-provider>
              <validation-provider
                  :name="$t('Address')"
                  rules="required"
                  autocomplete="off"
                  v-slot="{errors}"
                >
              <b-form-group>
                <label for="address" class="text-primary-dark font-weight-600">{{ $t('Address') }} <span class="text-danger-light">*</span></label>
                <b-form-input
                  id="address"
                  type="text"
                  v-model="teacherFormData.address"
                  :placeholder="$t('Enter Address')"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
              </validation-provider>
              <validation-provider
                  :name="$t('Location')"
                  rules="required"
                  autocomplete="off"
                  v-slot="{errors}"
                >
              <b-form-group>
                <label for="location" class="text-primary-dark font-weight-600">{{ $t('Location') }} <span class="text-danger-light">*</span></label>
                <v-select id="location" class="form-v-selects" :placeholder="$t('Province or States')" label="text" :filterable="false" :options="regions" v-model="selectedRegion" @search="searchRegion">
                  <template #search="{attributes, events}">
                    <input
                      class="vs__search 11"
                      v-bind="attributes"
                      v-on="events"
                      @click="setReadOnly"
                    />
                  </template>
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      {{ $t('No results found for') }} <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else>{{ $t('Typing to search for a Province or States') }}</em>
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.text }}
                      </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.text }}
                    </div>
                  </template>
                </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
              </validation-provider>
              <b-row>
              <b-form-group class="col-lg-6">
                <validation-provider
                  :name="$t('Email')"
                  rules="required"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <label for="email" class="text-primary-dark font-weight-600">{{ $t('Email') }} <span class="text-danger-light">*</span></label>
                <b-form-input
                  id="email"
                  type="text"
                  v-model="teacherFormData.email"
                  :placeholder="$t('Enter Email')"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group class="col-lg-6">
                <label for="phone" class="text-primary-dark font-weight-600">{{ $t('Phone number') }} <span class="text-danger-light">*</span></label>
                <b-form-input
                  id="phone"
                  type="text"
                  v-model="teacherFormData.phone_number"
                  :placeholder="$t('Enter Phone number')"
                ></b-form-input>
              </b-form-group>
              </b-row>
              <b-form-group>
                <validation-provider
                  :name="$t('About me')"
                  rules="required"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <label for="description" class="text-primary-dark font-weight-600">{{ $t('About me') }} <span class="text-danger-light">*</span></label>
                <b-form-textarea
                  id="description"
                  type="text"
                  v-model="teacherFormData.description"
                  :placeholder="$t('Enter about me')"
                  rows="5"
                  max-rows="10"
                ></b-form-textarea>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <h3 class="content-header-title text-primary-dark mt-4">{{ $t('Social media') }}</h3>
              <b-row>
                <b-form-group class="col-lg-6">
                  <label for="website" class="text-primary-dark font-weight-600">{{ $t('Website') }}</label>
                  <b-form-input
                    id="website"
                    type="text"
                    v-model="teacherFormData.website_url"
                    :placeholder="$t('Paste your link here')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-lg-6">
                  <label for="facebook_URL" class="text-primary-dark font-weight-600">{{ $t('Facebook URL') }}</label>
                  <b-form-input
                    id="facebook_URL"
                    type="text"
                    v-model="teacherFormData.facebook_url"
                    :placeholder="$t('Paste your link here')"
                  ></b-form-input>
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group class="col-lg-6">
                  <label for="twitter_URL" class="text-primary-dark font-weight-600">{{ $t('Twitter URL') }}</label>
                  <b-form-input
                    id="twitter_URL"
                    type="text"
                    v-model="teacherFormData.twitter_url"
                    :placeholder="$t('Paste your link here')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-lg-6">
                  <label for="instagram_URL" class="text-primary-dark font-weight-600">{{ $t('Instagram URL') }}</label>
                  <b-form-input
                    id="instagram_URL"
                    type="text"
                    v-model="teacherFormData.instagram_url"
                    :placeholder="$t('Paste your link here')"
                  ></b-form-input>
                </b-form-group>
              </b-row>
              <b-row class="justify-content-center mt-4">
                <b-button type="reset" @click="onPrevious" pill class="btn-default text-primary-dark font-weight-bold px-5">{{ $t('Previous') }}</b-button>
                <b-button type="submit" pill :disabled="isLoading" class="btn-primary-dark ml-3 font-weight-bold px-5"><b-spinner small class="mr-3" v-if="isLoading"  label="Loading..."/>{{ $t('Next') }}</b-button>
              </b-row>
            </b-form>
            </ValidationObserver>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>

import StepFormRegister from '../../components/form/StepFormRegister'
import Api from '../../api/api'
import 'vue-select/dist/vue-select.css'
import '@livelybone/vue-datepicker/lib/css/index.css'

export default {
  name: 'TeacherRegisterInformation',
  title: ' | Teacher register information',
  components: {
    StepFormRegister
  },
  data () {
    return {
      currentStep: 1,
      titleListSteps: [this.$t('Start'), this.$t('Teacher info'), this.$t('Yoga info'), this.$t('Document'), this.$t('Payment'), this.$t('Finished')],
      teacherFormData: {
        id: null,
        user: null,
        name: null,
        teacher_id: null,
        teacher_type: 1, // RTY
        date_of_birth: '2020-01-01',
        address: null,
        email: null,
        phone_number: null,
        nationality: null,
        country_of_residence: null,
        region: null,
        description: null,
        website_url: null,
        facebook_url: null,
        twitter_url: null,
        instagram_url: null,
        status: 1,
        member_since: null
      },
      selectedRegion: null,
      regions: [],
      countries: [],
      isLoading: false,
      dayStr: [this.$t('Sun'), this.$t('Mon'), this.$t('Tue'), this.$t('Wed'), this.$t('Thu'), this.$t('Fri'), this.$t('Sat')],
      date: '2020-01-01'
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    const user = await this.$store.getters.userProfile
    await this.$store.dispatch('getTeacher', { user: user.id })
    this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    const teacherId = localStorage.getItem('teacherId')
    if (teacherId) {
      this.getTeacher(teacherId)
    }
    this.getCountry()
    this.teacherFormData.user = user.id
  },
  computed: {
    currentLanguage () {
      return this.$store.getters.currentLanguage
    },
    userProfile () {
      return this.$store.getters.userProfile
    },
    teacherId () {
      return this.$store.getters.teacherId
    }
  },
  methods: {
    dateBirthDay (val) {
      this.date = val
      this.teacherFormData.date_of_birth = val
    },
    setReadOnly (el) {
      setTimeout(() => {
        el.target.removeAttribute('readonly')
      }, 80)
    },
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    },
    onSubmit () {
      this.$refs.form.validate().then(result => {
        if (result) {
          this.teacherFormData.region = this.selectedRegion.value
          if (this.teacherFormData.id === null || this.teacherFormData.id === undefined) {
            this.createTeacher()
          } else {
            this.updateTeacher()
          }
        }
      })
    },
    createTeacher () {
      this.isLoading = true
      delete this.teacherFormData.member_since
      Api.createTeacher(this.teacherFormData)
        .then((response) => {
          this.isLoading = false
          localStorage.setItem('teacherId', response.data.id)
          this.$router.push({ name: 'teacherRegisterYoga' })
        })
        .catch(() => {
          this.isLoading = false
          const textError = this.$t('Unsuccessfully register teacher')
          this.$notify({
            group: 'error',
            title: this.$t('Register teacher'),
            text: textError
          })
        })
    },
    updateTeacher () {
      this.isLoading = true
      delete this.teacherFormData.member_since
      Api.updateTeacher(this.teacherFormData)
        .then((response) => {
          this.isLoading = false
          localStorage.setItem('teacherId', response.data.id)
          this.$router.push({ name: 'teacherRegisterYoga' })
        })
        .catch(() => {
          this.isLoading = false
          const textError = this.$t('Unsuccessfully register teacher')
          this.$notify({
            group: 'error',
            title: this.$t('Register teacher'),
            text: textError
          })
        })
    },
    onPrevious () {
      const fullPath = '/apply-type?type=RTY'
      this.$router.push(fullPath)
    },
    getTeacher (id) {
      Api.teacherDetail({ id: id }).then(response => {
        if (response) {
          for (const property in this.teacherFormData) {
            if (property in response.data) {
              this.teacherFormData[property] = response.data[property]
            }
          }
          this.selectedRegion = { value: response.data.region, text: response.data.region_name }
        }
      })
    },
    getCountry () {
      Api.country({ limit: 300 }).then(response => {
        if (response.data) {
          this.countries = []
          response.data.results.forEach(country => {
            const url = country.url.split('/')
            this.countries.push({ value: url[url.length - 2], text: country.name })
          })
        }
      })
    },
    searchRegion (searchText, loading) {
      this.searchText = searchText
      if (this.searchText) {
        this.regions = []
        loading(true)
        Api.searchRegion({ q: this.searchText }).then(response => {
          if (response.data.results) {
            response.data.results.forEach(region => {
              const url = region.url.split('/')
              this.regions.push({ value: url[url.length - 2], text: region.display_name })
            })
          }
          loading(false)
        }).catch(() => {
          loading(false)
        })
      }
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>
<style >

</style>
